:root{
  --height_short:600px;
  --height_tall:800px;
}






/*

 ______    ____    _   _   _______    _____ 
|  ____|  / __ \  | \ | | |__   __|  / ____|
| |__    | |  | | |  \| |    | |    | (___  
|  __|   | |  | | | . ` |    | |     \___ \ 
| |      | |__| | | |\  |    | |     ____) |
|_|       \____/  |_| \_|    |_|    |_____/ 
                                            
                                           
*/





.Bebas{
  font-family: 'Bebas', sans-serif;
}

.Urbanist {
  font-family: 'Urbanist', sans-serif;
}

.Gelasio {
  font-family: 'Gelasio', sans-serif;
}

.PermanentMarker {
  font-family: 'Permanent Marker', sans-serif;
}




/*Bebas*/

@font-face {
  font-family: 'Bebas';
  src: url('./../public/fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




/*Urbanist*/

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Italic styles */
@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./../public/fonts/Urbanist/static/Urbanist-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}




/*Gelasio*/

/* Define Gelasio Regular */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Define Gelasio Italic */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Define Gelasio Medium */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Define Gelasio Medium Italic */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Define Gelasio SemiBold */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Define Gelasio SemiBold Italic */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* Define Gelasio Bold */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Define Gelasio Bold Italic */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Define Gelasio Variable Font */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-VariableFont_wght.ttf') format('truetype');
  font-weight: 400 700; /* It covers multiple weights between 400 and 700 */
  font-style: normal;
}

/* Define Gelasio Italic Variable Font */
@font-face {
  font-family: 'Gelasio';
  src: url('./../public/fonts/Gelasio/Gelasio-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 400 700; /* It covers multiple weights between 400 and 700 */
  font-style: italic;
}









/*Permanent Marker*/
@font-face {
  font-family: 'Permanent Marker';
  src: url('./../public/fonts/PermanentMarker-Regular.ttf') format('truetype');
  font-weight: normal; /* It covers multiple weights between 400 and 700 */
  font-style: normal;
}
