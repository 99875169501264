.home-wrap {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-console{
    width:95vw;
    height:80vh;
    max-width: 750px;
    max-height:1000px;
    background:white;
    border-radius:5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.store-header{
    width:100%;
    height:140px;
    padding:10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    background: linear-gradient(45deg, #ffc7a8, #fa8c43);
    border-top-left-radius:5px;
    border-top-right-radius:5px;
}

.image-wrap{
    width:120px;
    height:120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.image-wrap img{
    max-height:100%;
    border-radius: 4px;
}
.text-wrap{
    height:120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:10px;
}

.text-wrap .title{
    font-size: 36px;
    line-height: 34px;
    font-weight:400;
}
.text-wrap .slogan{
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    padding-left:5px;
    margin-top:-2px;
}

.redeem-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    flex-grow: 1;
}
.redeem-wrap .title{
    font-size:52px;
}


.redeem-wrap .redeem-this-asset-wrap{
    flex-grow:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top:25px;
    position:relative;
}
.redeem-wrap .redeem-this-asset-wrap .text-box.hide{
    display:none;
}
.redeem-wrap .redeem-this-asset-wrap .file-wrap{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 20px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    cursor:pointer;
    transition:.2s;
    z-index:999 !important;
    position:relative;
    background-color:rgb(255, 255, 255);
}
.redeem-wrap .redeem-this-asset-wrap .file-wrap:hover{
    border: 1px solid #949494;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}
.redeem-wrap .redeem-this-asset-wrap .file-wrap.downloaded:hover{
    border: 1px solid #c4c4c4;
    box-shadow: none !important;
    cursor:default;
}
.redeem-wrap .redeem-this-asset-wrap .file-wrap.error:hover{
    border: 1px solid #c4c4c4;
    box-shadow: none !important;
    cursor:default;
}
.redeem-wrap .redeem-this-asset-wrap .file-wrap.loading {
    border: 1px solid #949494;
    box-shadow: 0 0 4px rgba(0, 0, 0, 1);
    animation: shadowGlow 2s infinite alternate; /* Runs the animation infinitely forwards and backwards */
}


@keyframes shadowGlow {
    from {
        box-shadow: 0 0 4px rgba(0, 0, 0, 1);
    }
    to {
        box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }
}


.redeem-wrap .redeem-this-asset-wrap .fileFolderIcon{
    max-width:120px;
}
.redeem-wrap .redeem-this-asset-wrap .fileDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.redeem-wrap .redeem-this-asset-wrap .fileDetails .fileName{
    font-weight: 700;
    font-size: 24px;
}
.redeem-wrap .redeem-this-asset-wrap .fileDetails .spec1{
    font-size: 20px;
    line-height: 20px;
    margin-block-start: 5px;
}
.redeem-wrap .redeem-this-asset-wrap .fileDetails .spec2{
    font-size: 20px;
    line-height: 20px;
}
.redeem-wrap .redeem-this-asset-wrap .fileDetails .spec3{
    font-style: italic;
    font-size: 12px;
}

.redeem-wrap .redeem-this-asset-wrap .progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  margin-block-start: 20px;
  transition:.5s;
}
.redeem-wrap .redeem-this-asset-wrap .progress-bar.succeed {
    background-color: #41ff6d;
}
.redeem-wrap .redeem-this-asset-wrap .progress-bar.succeed .progress{
    background-color: #41ff6d;
}

.redeem-wrap .redeem-this-asset-wrap .progress {
  height: 100%;
  background-color: #3498db;
  transition: width 0.3s ease-in-out, background-color .5s; /* Smooth transition effect */
}
.redeem-wrap .redeem-this-asset-wrap .text-box{
    margin-block-start: 10px;
    position:relative;
}
.redeem-wrap .redeem-this-asset-wrap .text-box .text1 {
    opacity: 0;
    transform: translateY(20px);
    animation: appearUp .3s ease-in forwards;
    animation-delay: 1s; /* Delay the 'appearUp' animation by 0.5s */
    position:relative;
}
.redeem-wrap .redeem-this-asset-wrap .text-box .text1.exit {
    animation: exitUp .3s ease-out forwards;
}
.redeem-wrap .redeem-this-asset-wrap .text-box .text2{
    opacity:0;
    transform: translateY(20px);
    animation: appearUp2 .3s ease-in forwards;
    position:relative;
    text-align:center;
}
.redeem-wrap .redeem-this-asset-wrap .text-box .text2.exit{
    animation: exitUp2 .3s ease-out forwards;
}
.redeem-wrap .redeem-this-asset-wrap .text-box .text3{
    opacity:0;
    transform: translateY(20px);
    animation: appearUp2 .3s ease-in forwards;
    position:relative;
}
@keyframes appearUp {
    from{
        opacity:0;
        transform: translateY(20px);
    }
    to{
        opacity:1;
        transform: translateY(0px);
    }
}
@keyframes appearUp2 {
    from{
        opacity:0;
        transform: translateY(20px);
    }
    to{
        opacity:1;
        transform: translateY(-10px);
    }
}
@keyframes exitUp {
    from{
        opacity:1;
        transform: translateY(0px);
    }
    to{
        opacity:0;
        transform: translateY(-20px);
    }

}
@keyframes exitUp2 {
    from{
        opacity:1;
        transform: translateY(-10px);
    }
    to{
        opacity:0;
        transform: translateY(-30px);
    }

}



.redeem-wrap form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width:100%;
    flex-grow: 1;
    padding-top:45px;
}
.redeem-wrap form.hide-form{
    display:none;
}
.redeem-wrap form label{
    font-size:30px;
    font-family: 'Urbanist', sans-serif;
}
.redeem-wrap form input{
    width:300px;
    padding:5px;
    line-height:30px;
    font-size: 16px;
    transition:.5s;
}

.redeem-wrap form input::placeholder {
    color: #bbbbbb; /* Change the color of the placeholder text */
    font-size: 16x !important; /* Change the font size */
    font-style: italic; /* Make the placeholder italic */
    opacity: 1; /* Adjust opacity if needed */
  }
.redeem-wrap form .order-id-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block-end: 20px;
    transition:.5s;
}
.redeem-wrap form .order-id-inputs.loading input{
    background-color: rgb(225, 225, 225);
}
.redeem-wrap form .order-id-inputs.error input{
    background-color: #fa8989;
}
.redeem-wrap form .order-id-inputs label{
    align-self: flex-start;
}

.redeem-wrap form .order-secret-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block-end: 20px;
    transition:.5s;
}
.redeem-wrap form .order-secret-inputs.loading input{
    background-color: rgb(225, 225, 225);
}
.redeem-wrap form .order-secret-inputs.error input{
    background-color: #fa8989;
}
.redeem-wrap form .order-secret-inputs label{
    align-self: flex-start;
}

.redeem-wrap form button{
    padding:10px;
    width:200px;
    cursor:pointer;
}

.redeem-wrap form .error-message{
    width:300px;
    height:50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:#FF4D4F;
    font-weight:900;
}

.redeem-wrap form .error-animation {
    opacity:0;
    transform: translateY(-30px),scale(.5);
    animation: fadeIn .5s ease-in forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform:translateY(-30px),scale(.5);
    }
    to {
        opacity: 1;
        transform:translateY(0px),scale(1);
    }
}










/* General notification styles */
.notification {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    max-width: 300px;
    margin: 10px 0;
    border: 1px solid;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  }
  
  /* Info notification */
  .notification.info {
    background-color: #e6f0ff;
    color: #0052cc;
    border-color: #0052cc;
  }
  
  .notification.info .icon {
    margin-right: 10px;
    color: #0052cc;
    font-size: 20px;
  }
  
  /* Warning notification */
  .notification.warning {
    background-color: #fff4e5;
    color: #cc8400;
    border-color: #cc8400;
  }
  
  .notification.warning .icon {
    margin-right: 10px;
    color: #cc8400;
    font-size: 20px;
  }
  
  /* Error notification */
  .notification.error {
    background-color: #ffe6e6;
    color: #d32f2f;
    border-color: #d32f2f;
  }
  
  .notification.error .icon {
    margin-right: 10px;
    color: #d32f2f;
    font-size: 20px;
  }
  
  /* Success notification */
  .notification.success {
    background-color: #e6ffe6;
    color: #28a745;
    border-color: #28a745;
  }
  
  .notification.success .icon {
    margin-right: 10px;
    color: #28a745;
    font-size: 20px;
  }













/* Loader 5 */
.loader-5 {
	height: 32px;
	width: 32px;
	-webkit-animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-5-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-5::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: auto;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #fa8c43;
	border-radius: 50%;
	-webkit-animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-2 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(24px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-2 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader-5::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: auto;
	bottom: 0; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #fa8c43;
	border-radius: 50%;
	-webkit-animation: loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-3 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-24px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-3 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader-5 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
}
.loader-5 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: auto; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #fa8c43;
	border-radius: 50%;
	-webkit-animation: loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-4 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(0, 24px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-4 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, 24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader-5 span::after {
	content: "";
	display: block;
	position: absolute;
	top: auto; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #fa8c43;
	border-radius: 50%;
	-webkit-animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-5-5 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(0, -24px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader-5-5 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, -24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}